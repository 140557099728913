* {
  cursor: none;
  margin: 0;
  padding: 0;
}

body,
html {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  height: 100vh;
  width: 100vw;
  margin: 0;

  height: 100dvh;
  width: 100dvw;

  & > #root {
    height: 100%;
  }
}

::-moz-selection {
  color: white;
  background: black;
}

::selection {
  color: white;
  background: black;
}

.container::before {
  content: "";
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(farthest-side, #fff 95%, transparent 100%) calc(var(--x) - 0.75em)
    calc(var(--y) - 0.75em) / 1.5em 1.5em fixed no-repeat;
  mix-blend-mode: difference;
  pointer-events: none;
}

.container {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: #fff;

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .title {
    color: #fff;
    text-align: center;
    font-size: 72px;
    font-weight: 700;
    text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000, 1px 1px 0 #000,
      0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000;

    margin: 0;

    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);

    @media only screen and (min-width: 601px) and (max-width: 900px) {
      font-size: 60px;
    }
    @media only screen and (max-width: 600px) {
      font-size: 40px;
    }
  }

  .subtitle {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    margin: 15px 5px;
    padding-bottom: 5px;

    @media only screen and (min-width: 601px) and (max-width: 900px) {
      font-size: 14px;
      margin: 10px 3px;
    }
    @media only screen and (max-width: 600px) {
      font-size: 12px;
      margin: 0;
    }
  }

  .email {
    position: absolute;
    right: 25px;
    top: 25px;
    color: #000;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    text-decoration: underline;
  }
}

.logo {
  position: absolute;
  bottom: 28px;
  right: 28px;
}

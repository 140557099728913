.dot-logo {
  width: 36px;
  height: 36px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  font-size: 14px;
  overflow: hidden;

  justify-content: center;
  align-items: center;
}

.dot-logo:hover {
  background-color: white;
  font-weight: bold;
  font-size: 14px;
}

.logo-text {
  padding: 0 30px 0 10px;
  white-space: nowrap;
}
